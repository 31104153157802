<div class="modal" [ngClass]="{'active': showModal}">
  <div class="modal-content">
    <span class="close-button" (click)="closeModal()">&times;</span>
    <div>
      <h3 class="createTower" [innerHTML]="editTowerModal ? 'Update Tower' : 'Create Tower'"></h3>
      <div class="mt-5 mb-5">
        <div>
          <label class="col-form-label fw-bold fs-6">Tower Name</label>
          <input type="text" required [(ngModel)]="towerTitle" (change)="onTitleChange($event)" class="form-control form-control-lg form-control-solid" placeholder="my tower"
             name="title">
        </div>
        <div>
          <label class="col-form-label fw-bold fs-6">Description</label>
          <textarea [(ngModel)]="towerDescription" required (change)="onDescChange($event)" class="form-control form-control-lg form-control-solid textarea" placeholder="description"
           name="description" rows="4" cols="50"></textarea>
        </div>
        <div>
          <label class="col-form-label fw-bold fs-6">Trait Value</label>
          <input type="number" required [(ngModel)]="floorPrice" (change)="onPriceChange($event)" class="form-control form-control-lg form-control-solid" placeholder=""
             name="floorPrice">
        </div>
        <div>
          <label class="col-form-label fw-bold fs-6">Trait Type</label>
          <input type="number" required [(ngModel)]="floorValue" (change)="onPriceValueChange($event)" class="form-control form-control-lg form-control-solid" placeholder=""
             name="floorValue">
        </div>
        <div>
          <label class="col-form-label fw-bold fs-6">Segments</label>
          <select class="form-control form-control-lg form-control-solid" [(ngModel)]="selectedSegment" (change)="onSegmentSelect($event)">
            <option value="">select..</option>
            <option value="First Tower">First Tower</option>
            <option value="Crown Tower">Crown Tower</option>
          </select>
          <!-- <ng-multiselect-dropdown placeholder="" [settings]="dropdownSettings"
            [data]="allSegments" (onSelect)="onSegmentSelect($event)">
          </ng-multiselect-dropdown> -->
          <div class="d-flex">
            <span *ngFor="let item of selectedSegmentsArray let i = index" class="mt-3 d-flex segments_list">{{item}}</span>
          </div>
        </div>
        <div>
          <label class="col-form-label fw-bold fs-6">Link</label>
          <input type="text" required [(ngModel)]="towerLink" (change)="onLinkChange($event)" class="form-control form-control-lg form-control-solid" placeholder="https://"
             name="link">
        </div>
        <div class="btn-styling">
          <div *ngIf="editTowerModal; else createButton">
            <button type="button" class="btn btn-block btn-gradient mr-1 mb-5" (click)="handleUpdateTower()">Update Tower</button>
          </div>
          <ng-template #createButton>
            <button type="button" class="btn btn-block btn-gradient mr-1 mb-5" (click)="createTower()">Create Tower</button>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>