import { Component, OnInit, Input, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { ModalPopupComponent } from '../modal-popup/modal-popup.component';
import { TowerService } from '../tower.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
// import { biPencilSquare, biTrash } from 'bootstrap-icons/icons';

@Component({
  selector: 'app-towers',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css']
})
export class TowersComponent implements OnInit, OnChanges {
  // pencilIcon = biPencilSquare;
  // trashIcon = biTrash;
  public reload: boolean = false;
  public towerModal: boolean = false;
  public editTowerModal: boolean = false;
  reloadTower: any;
  towerData: [];
  editTowerVal: any;
  towers: any;
  per_page: any;
  total: any;
  page = 1
  key = 'name'
  dropdownSettings3: IDropdownSettings;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.reloadTower) {
      this.towerModal = false
      this.getAllTowers()
    }
  }

  @ViewChild(ModalPopupComponent) modalPopup: ModalPopupComponent;

  constructor(private service: TowerService, private router: Router,) {  }


  ngOnInit() {
    this.getAllTowers()
    if (this.reload) {
      this.getAllTowers()
    }
  }

  getAllTowers() {
    this.towers = [];
    // this.page, this.key, this.direction;
    this.service.getAllTowers().subscribe({
      next: (res: any) => {
        let data = res.data
        console.log(data)
        for (let i = 0; i < data.length; i++) {
          const element = data[i];
          this.towers.push(element)

        }
        let meta = data.meta
        this.per_page = meta.per_page
        this.total = meta.total
      },
      error: (err: any) => { },
      complete: () => { }
    })
  }

  handleCreateTower() {
    this.towerModal = !this.towerModal
  }

  handleEditTower(val: {}) {
    this.editTowerModal = !this.editTowerModal
    this.towerModal = !this.towerModal
    this.editTowerVal = val
  }

  handleDeleteTower(val) {
    Swal.fire({
      title: '<strong>Warning</strong>',
      icon: 'warning',
      html:
        ``,
      text: `About to delete ${val.name} tower ?`,
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Delete',

    }).then((result) => {
      if (result.isConfirmed) {
        const payload = {
          name: val.name,
          description: val.description,
          floorPrice: val.floorPrice,
          segment_ids: val.segment_ids,
          link: val.link,
        };

        this.service.deleteTower(val.id, payload).subscribe({
          next: (res: any) => {
            this.towerData = res.data
            Swal.fire('success', res.message, 'success')
          },
          error: (err: any) => {
            Swal.fire('Failed to delete tower', err.error.message, 'error')
            console.log(err)
          },
          complete: () => {
            Swal.fire({
              title: '<strong>Success</strong>',
              icon: 'success',
              html:
                ``,
              text: 'Tower Deleted Successfully',
              showCloseButton: false,
              showCancelButton: true,
              focusConfirm: false,
              // confirmButtonText: 'Publish to Discord',
              cancelButtonText: 'Return to Tower',
    
            })  
            location.reload()
          }
        })
      } else if (result.isDismissed) {
        return;
      } else {
        return;
      }
    })
  }

}
