import { RouterModule, Routes } from "@angular/router";
import { TowersComponent } from "./overview/overview.component";
import { NgModule } from "@angular/core";
import { manage_event } from '../auth/services/permission.guard';

const routes: Routes = [
    {
        path: '',
        canActivate: [manage_event],
        component: TowersComponent,
    },
  ];
  
  @NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
  })
  export class TowersRoutingModule { }