import { Component, OnInit, Input, OnChanges, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';
import { DomSanitizer,SafeResourceUrl } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SegmentsService } from 'src/app/modules/segments/segments.service';
import { TowerService } from '../tower.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-popup',
  templateUrl: './modal-popup.component.html',
  styleUrls: ['./modal-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalPopupComponent implements OnInit, OnChanges {
  form: FormGroup;
  @Input() showModal: boolean = false;
  @Input() editTowerModal: boolean = false;
  @Input() editTowerVal: any;
  @Input() iframeSrc: string = '';
  @Input() reloadTower: any;
  evenForm: FormGroup;
  allSegments: ['First Tower', 'Second Tower', 'Crown Tower'];
  ShowFilter = true;
  towerTitle: string = '';
  towerDescription: string = '';
  floorPrice: number;
  floorValue: number;
  selectedSegment: string = '';
  selectedSegmentsArray: string[] = [];
  towerLink: string = '';
  towerId: string = '';
  dropdownSettings: any = {};
  towerData: {
    link: string,
    name: string,
    floorPrice: number,
    floorValue: number,
    description: string,
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.editTowerVal) {
      
      this.towerTitle = this.editTowerVal.name || '';
      this.towerDescription = this.editTowerVal.description || '';
      this.floorPrice = this.editTowerVal.trait_value || null;
      this.floorValue = this.editTowerVal.trait_type || null;
      this.selectedSegment = this.editTowerVal.segment_ids || '';
      this.selectedSegmentsArray = this.editTowerVal.segment_ids || [];
      this.towerLink = this.editTowerVal.link || '';
      this.towerId = this.editTowerVal.id || '';

    }
  }

  constructor(
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
    private segmentService: SegmentsService,
    private service: TowerService, private router: Router,
  ) 
    { 
    }
    
  ngOnInit(): void {
    this.listAllSegments()

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      enableCheckAll: false,
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 2,
      allowSearchFilter: this.ShowFilter,
      placeholder: 'Select a segment',
      closeDropDownOnSelection: true
    };
  }

  cancel() {
    this.evenForm.reset()
    this.router.navigateByUrl('/sc/tower')
  }

  reload() {
    this.router.navigateByUrl('/sc/tower')
  }

  createTower() {
    const payload = {
      name: this.towerTitle,
      description: this.towerDescription,
      trait_value: this.floorPrice,
      trait_type: this.floorValue,
      segment_ids: this.selectedSegmentsArray,
      link: this.towerLink,
    };
    
      this.service.createTower(payload).subscribe({
        next: (res: any) => {
          this.towerData = res.data
          Swal.fire('success', res.message, 'success')
        },
        error: (err: any) => {
          Swal.fire('Failed to create tower', err.error.message, 'error')
          console.log(err)
        },
        complete: () => {
          Swal.fire({
            title: '<strong>Success</strong>',
            icon: 'success',
            html:
              ``,
            text: 'Tower Created Successfully',
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
            // confirmButtonText: 'Publish to Discord',
            cancelButtonText: 'Return Home',
  
          }).then((result) => {
            if (result.isConfirmed) {
              this.reloadTower = result
              this.reload()
              this.showModal = !this.showModal;         
              this.towerTitle = '';
              this.towerDescription = '';
              this.floorPrice = null;
              this.floorValue = null;
              this.selectedSegment = '';
              this.selectedSegmentsArray = [];
              this.towerLink = '';
              location.reload()
            } else if (result.isDismissed) {
              this.reloadTower = result
              this.showModal = !this.showModal;  
              location.reload()
            } else {
              this.cancel()
            }
          })
          this.reloadTower = true
          this.showModal = !this.showModal;   
        }
      })

  }

  handleUpdateTower() {
    const payload = {
      name: this.towerTitle,
      description: this.towerDescription,
      trait_type: this.floorValue,
      trait_value: this.floorPrice,
      segment_ids: this.selectedSegmentsArray,
      link: this.towerLink,
    };

    this.service.updateTower(this.towerId, payload).subscribe({
      next: (res: any) => {
        this.towerData = res.data
        Swal.fire('success', res.message, 'success')
        console.log(res)
      },
      error: (err: any) => {
        Swal.fire('Failed to update tower', err.error.message, 'error')
        console.log(err)
      },
      complete: () => {
        Swal.fire({
          title: '<strong>Success</strong>',
          icon: 'success',
          html:
            ``,
          text: 'Tower Updated Successfully',
          showCloseButton: true,
          showCancelButton: true,
          focusConfirm: false,
          // confirmButtonText: 'Publish to Discord',
          cancelButtonText: 'Return Home',

        }).then((result) => {
          if (result.isConfirmed) {
            this.reloadTower = result
            this.reload()
            location.reload()
            this.showModal = !this.showModal;     
            this.showModal = false
            localStorage.removeItem('towerVal')
            this.towerTitle = '';
            this.towerDescription = '';
            this.floorPrice = null;
            this.floorValue = null;
            this.selectedSegment = '';
            this.selectedSegmentsArray = [];
            this.towerLink = '';
          } else if (result.isDismissed) {
            location.reload()
          } else {
          }
        })
        this.reloadTower = true
        this.showModal = !this.showModal;   
      }
    })
  }

  initForm() {
    this.evenForm = this.fb.group({
      towerLink: ['', Validators.required],
      towerTitle: ['', Validators.required],
      towerDescription: [''],
      floorPrice: ['', Validators.required],
      floorValue: ['', Validators.required],
      selectedSegment: ['', Validators.required],
      segments: [null],

    })
  }

  closeModal() {
    this.showModal = !this.showModal;
    this.editTowerModal = false
    localStorage.removeItem('towerVal')
    this.towerTitle = '';
    this.towerDescription = '';
    this.floorPrice = null;
    this.floorValue = null;
    this.selectedSegment = '';
    this.selectedSegmentsArray = [];
    this.towerLink = '';
  }
  
  safeIframeSrc(): SafeResourceUrl {

    return this.sanitizer.bypassSecurityTrustResourceUrl(this.iframeSrc);
  }

  onTitleChange(e: any) {
    this.towerTitle = e.target.value
  }

  onDescChange(e: any) {
    this.towerDescription = e.target.value
  }

  onPriceChange(e: any) {
    this.floorPrice = e.target.value
  }

  onPriceValueChange(e: any) {
    this.floorValue = e.target.value
  }

  onLinkChange(e: any) {
    this.towerLink = e.target.value
  }

  onSegmentSelect(val: any) {
    this.selectedSegment = val.target.value;
    const selectedSegment = val.target.value;
  
    const index = this.selectedSegmentsArray.indexOf(selectedSegment);
  
    if (index === -1) {

      this.selectedSegmentsArray.push(selectedSegment);
    } else {

      this.selectedSegmentsArray.splice(index, 1);
    }
  }

  listAllSegments() {
    this.segmentService.getAllSegments().subscribe({
      next: (res: any) => {
        console.log(res)
        this.allSegments = res.data
      },
      error: (err: any) => {
        console.log(err)
      }
    })
  }

}
