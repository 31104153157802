<div>
  <div class="ml-auto btn-styling">
    <button type="button" class="btn btn-block btn-gradient mr-1 mt-3 mb-5" (click)="handleCreateTower()">Create Tower</button>

    <app-modal-popup [showModal]="towerModal" [editTowerModal]="editTowerModal" [editTowerVal]="editTowerVal" [reloadTower]="reloadTower"></app-modal-popup>
  </div>
  <div class="card">
    <div class="table-responsive">

      <table class="table align-middle table-row-dashed fs-6 gy-5">
        <thead>
          <tr class="text-start text-muted fw-bold fs-7 text-uppercase gs-0 " style="cursor: pointer">
            <th class="ps-7">Tower Name</th>
            <th>Floor Price ($SOL)</th>
            <th>Segment</th>
            <th>Link </th>
            <th>Description</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of towers  | paginate: { itemsPerPage: 10, currentPage: 1 }; let i = index">
            <td class="ps-7">
              {{ item.name }}
            </td>
            <td class="ps-7"> 
              {{ item.floor_price }}
            </td>
            <td> {{ item.segment_ids.length |number :'0.0-2' }} </td>
            <td  class="ps-7 linkStyling"> {{ item.link }} </td>
            <td class="ps-7 descStyling">
              <span class="">
                {{ item.description }}
              </span>
            </td>
            <td>
              <span class="btn btn-edit" (click)="handleEditTower(item)">
                <svg stroke="#565674" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" class="icon-sm" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M12 20h9"></path><path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path></svg>
              </span>
              <span class="btn deleteIcon" (click)="handleDeleteTower(item)">
                <svg stroke="#565674" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" class="icon-sm" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <pagination-controls class="d-flex justify-content-center my-pagination" [directionLinks]="true" [responsive]="true"></pagination-controls>
  </div>
</div>
